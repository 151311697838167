// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_58cVy{display:flex;flex-direction:column;height:100%;justify-content:space-between}.filters_sT\\+Bg{align-items:flex-end;display:flex;gap:1rem}.sticky_-J\\+Fj{bottom:1rem;display:flex;flex-direction:row;margin:0 auto;min-width:20rem;position:sticky;width:-moz-fit-content;width:fit-content;z-index:1}.filterMenu_8\\+Ptt{display:flex;flex-direction:column}.headerRight_e4d8-{flex:1}.filterToggleItem_AVYPJ{cursor:pointer;padding:.5rem}.filterToggleItem_AVYPJ:hover{background-color:rgba(0,0,0,.2)}.flexCell_G\\+9-8{display:flex;gap:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_58cVy",
	"filters": "filters_sT+Bg",
	"sticky": "sticky_-J+Fj",
	"filterMenu": "filterMenu_8+Ptt",
	"headerRight": "headerRight_e4d8-",
	"filterToggleItem": "filterToggleItem_AVYPJ",
	"flexCell": "flexCell_G+9-8"
};
module.exports = ___CSS_LOADER_EXPORT___;
